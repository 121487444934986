import styles from "./banner.module.css";
import Container from "../Container/Container";
import CstLink from "../Clickable/Link/Link";

function Banner() {
 return (
  <div className="">
   <div className={styles.banner}>
    <Container classes={styles.container_height}>
     <div className={styles.infos}>
      <div className={styles.intro}>
       <div className={styles.point}></div>
       <h5>We are Master of Construction Services</h5>
      </div>
      <h2>We specialize in top-tier Construction Services</h2>
      <p>
       At Scantech Group, we offer expert plumbing, heating, air conditioning, and electrical services with guaranteed quality and customer
       satisfaction.
      </p>
      <CstLink href="https://scantech-ks.com/contact" type="outline-white" title="Contact us" target="_blank" />
     </div>
    </Container>
   </div>
  </div>
 );
}

export default Banner;
