import Container from "../../Container/Container";
import styles from "./full.module.css";
import classNames from "classnames";

function FullImage({ children, image, reverse=false }) {
  return (
    <div className={classNames(styles.service_container)}>
      <Container>
        <div className={classNames({
          [styles.full_height]: true,
          [styles.service]: true,
          [styles.reverse]: reverse
        })}>
          <div className={styles.infos}>
            {children}
          </div>
          <div className={styles.image}>
            <img src={image} alt="Banner" />
          </div>
        </div>
      </Container>
    </div>
  );
}

export default FullImage;
