import Container from "../../Container/Container";
import styles from "./small.module.css";

function SmallImage() {
  return (
    <div className={styles.banner_container}>
      <Container>
        <div className={styles.banner}>
          <div className={styles.infos}>
            <div>
              <h5 className={styles.intro}>ABOUT CONSTRUCTION SERVICES</h5>
              <h3 className={styles.title}>Our Company Provide The Best <span>Construction Services</span></h3>
              <p className={styles.txt}>
                At Scantech Group, we specialize in top-tier construction services, offering expert plumbing, heating, air conditioning, and electrical solutions. 
              </p>
              <p className={styles.txt}>
                Our team of certified professionals ensures quality, reliability, and complete customer satisfaction in every project. We proudly partner with major companies to deliver the best services possible.
              </p>
            </div>
            <div className={styles.boxes}>
              <div className={styles.box}>
                <img src="/assets/icons/tick-circle.svg" alt="Tick" />
                <span>Planning</span>
              </div>
              <div className={styles.box}>
                <img src="/assets/icons/tick-circle.svg" alt="Tick" />
                <span>Replacing</span>
              </div>
              <div className={styles.box}>
                <img src="/assets/icons/tick-circle.svg" alt="Tick" />
                <span>Installing</span>
              </div>
            </div>
          </div>
          <div className={styles.images}>
            <img src="/assets/images/other-banner1.png" alt="Banner" />
          </div>
        </div>
      </Container>
    </div>
  );
}

export default SmallImage;
