import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import styles from "./header.module.css";
import classNames from "classnames";
import Container from "../Container/Container";
import CstLink from "../Clickable/Link/Link";
import MobileMenu from "../mobile/mobileMenu/MobileMenu";
import { useMediaQuery } from "react-responsive";

function Header() {

 const Contact = ({ href, icon, txt }) => (
  <div className={styles.contact}>
   <img src={icon} alt={txt} />
   <Link to={href}>{txt}</Link>
  </div>
 );
 const isMobile = useMediaQuery({ query: `(max-width: 1024px)` });
 const [mobileMenu, setMobileMenu] = useState(false);

 return (
  <header>
   {isMobile ? (
    <div className={styles.mobile_header_container}>
     <Container>
      <div className={styles.header_mobile}>
       <Link to="/">
        <img src="/assets/images/logo-footer.svg" alt="Logo" />
       </Link>
       <button onClick={() => setMobileMenu(true)}>
        <img src="/assets/images/hamburger.svg" alt="Logo" />
       </button>
      </div>
      <MobileMenu mobileMenu={mobileMenu} setMobileMenu={setMobileMenu} />
     </Container>
    </div>
   ) : (
    <>
     <div className={styles.contact_info}>
      <Container>
       <div className={styles.container}>
        <div className={styles.contacts}>
         <Contact href="mailto:info@scantech-ks.com" icon="/assets/icons/ic_outline-email.svg" txt="info@scantech-ks.com" />
         <Contact href="tel:+38338580000" icon="/assets/icons/solar_phone-linear.svg" txt="+383 38 58 00 00" />
        </div>
        <div className={classNames(styles.contacts, styles.socials_container)}>
         <span>Follow us:</span>
         <div className={styles.socials}>
          <Link to="https://www.facebook.com/scantechllc/">
           <img src="/assets/icons/gg_facebook.svg" alt="Facebook" />
          </Link>
          <Link to="https://www.instagram.com/scantech.ks/">
           <img src="/assets/icons/ri_instagram-fill.svg" alt="Instagram" />
          </Link>
          <Link to="">
           <img src="/assets/icons/mdi_linkedin.svg" alt="Linkedin" />
          </Link>
          <Link to="">
           <img src="/assets/icons/mdi_youtube.svg" alt="Youtube" />
          </Link>
         </div>
        </div>
       </div>
      </Container>
     </div>

     <Container>
      <div className={styles.header}>
       <Link to="/">
        <img src="/assets/blue-logo.svg" alt="Logo" />
       </Link>
       <ul>
        <li>
         <NavLink target="_blank" to="https://scantech-ks.com/">Home</NavLink>
        </li>
        <li>
         <NavLink target="_blank" to="https://scantech-ks.com/about-us">About</NavLink>
        </li>
        <li>
         <NavLink target="_blank" to="https://scantech-ks.com/#xray-scanners">X-Ray</NavLink>
        </li>
        <li>
         <NavLink to="">Construction</NavLink>
        </li>
        <li>
         <NavLink target="_blank" to="https://scantech-ks.com/#academy">Academy</NavLink>
        </li>
        {/* <li>
         <NavLink>Blog</NavLink>
        </li> */}
       </ul>
       <CstLink type="outline" href="https://scantech-ks.com/contact" title="Contact us" target="_blank" />
      </div>
     </Container>
    </>
   )}
  </header>
 );
}

export default Header;
