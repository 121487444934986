import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./mobileMenu.css";

export default function MobileMenu({ mobileMenu, setMobileMenu }) {

  const closeMenu = () => {
    setMobileMenu(false);
  }

 return (
  <div className={`mobile-side-menu ${mobileMenu && "active"}`} onClick={() => setMobileMenu(false)}>
   <div className="content" onClick={(e) => e.stopPropagation()}>
    {/* <div className=" heading">
     <h5>Menu</h5>
     <svg
      onClick={() => setMobileMenu(false)}
      id="fi-rr-cross-circle"
      xmlns="http://www.w3.org/2000/svg"
      width="25.421"
      height="25.421"
      viewBox="0 0 25.421 25.421"
     >
      <path
       id="Path_420"
       data-name="Path 420"
       d="M16.491,8.017a1.059,1.059,0,0,0-1.5,0l-2.739,2.739L9.515,8.017a1.059,1.059,0,1,0-1.5,1.5l2.739,2.739L8.017,14.993a1.059,1.059,0,1,0,1.5,1.5l2.739-2.739,2.739,2.739a1.059,1.059,0,1,0,1.5-1.5l-2.739-2.739,2.739-2.739A1.059,1.059,0,0,0,16.491,8.017Z"
       transform="translate(0.456 0.456)"
       fill="white"
      />
      <path
       id="Path_421"
       data-name="Path 421"
       d="M12.711,0A12.711,12.711,0,1,0,25.421,12.711,12.711,12.711,0,0,0,12.711,0Zm0,23.3A10.592,10.592,0,1,1,23.3,12.711,10.592,10.592,0,0,1,12.711,23.3Z"
       transform="translate(0 0)"
       fill="white"
      />
     </svg>
    </div> */}
    <ul className="menu">
     <>
      <li>
        <Link target="_blank" to="https://scantech-ks.com/">Home</Link>
      </li>
      <li>
        <Link target="_blank" to="https://scantech-ks.com/about-us">About</Link>
      </li>
      <li>
        <Link target="_blank" to="https://scantech-ks.com/#xray-scanners">X-Ray</Link>
      </li>
      <li>
        <Link to="/" onClick={closeMenu}>Construction</Link>
      </li>
      <li>
        <Link target="_blank" to="https://scantech-ks.com/#academy">Academy</Link>
      </li>
      <li>
        <Link target="_blank" to="https://scantech-ks.com/contact">Contact us</Link>
      </li>
     </>
    </ul>
   </div>
  </div>
 );
}
