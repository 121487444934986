import styles from "./container.module.css";
import classNames from 'classnames';

function Container({ children, classes={} }) {
  return (
    <div className={classNames(styles.container, classes)}>
      {children}
    </div>
  );
}

export default Container;
