import classNames from "classnames";
import Banner from "../../components/Banner/Banner";
import Container from "../../components/Container/Container";
import Header from "../../components/Header/Header";
import SmallImage from "../../components/OtherBanners/SmallImage/SmallImage";
import styles from "./home.module.css";
import Services from "../../components/Services/Services";
import FullImage from "../../components/OtherBanners/FullImage/FullImage";
import CstLink from "../../components/Clickable/Link/Link";
import Partners from "../../components/Partners/Partners";
import Footer from "../../components/Footer/Footer";

function Home() {
 return (
  <div>
   <Header />
   <Banner />
   <Partners />
   <SmallImage />
   <Services />
   <Container>
    <div className={styles.why_us}>
     <h2 className={styles.title}>
      Why <span>Choose Us ?</span>
     </h2>
     <div className={styles.boxes}>
      <div className={styles.box}>
       <div className={styles.circle}>
        <img src="/assets/icons/whyus/expert 1.svg" alt="Icon" />
        <span>Certified Experts</span>
       </div>
       <img src="/assets/icons/arrow-down.svg" alt="Arrow down" />
       <p className={styles.txt}>Skilled and experienced professionals.</p>
      </div>

      <div className={styles.box}>
       <div className={styles.circle}>
        <img src="/assets/icons/whyus/achievement 1.svg" alt="Icon" />
        <span>Quality Assurance</span>
       </div>
       <img src="/assets/icons/arrow-down.svg" alt="Arrow down" />
       <p className={styles.txt}>Top materials and industry best practices.</p>
      </div>
      
      <div className={styles.box}>
       <div className={styles.circle}>
        <img src="/assets/icons/whyus/target-audience 1.svg" alt="Icon" />
        <span>Customer Centric</span>
       </div>
       <img src="/assets/icons/arrow-down.svg" alt="Arrow down" />
       <p className={styles.txt}>Your satisfaction is our priority.</p>
      </div>

      <div className={styles.box}>
       <div className={styles.circle}>
        <img src="/assets/icons/whyus/competitive 1.svg" alt="Icon" />
        <span>Competitive Pricing</span>
       </div>
       <img src="/assets/icons/arrow-down.svg" alt="Arrow down" />
       <p className={styles.txt}>Transparent and fair rates.</p>
      </div>
      
      <div className={styles.box}>
       <div className={styles.circle}>
        <img src="/assets/icons/whyus/emergency 1.svg" alt="Icon" />
        <span>24/7 Emergency Support</span>
       </div>
       <img src="/assets/icons/arrow-down.svg" alt="Arrow down" />
       <p className={styles.txt}>Always available when you need us.</p>
      </div>
     </div>
    </div>
   </Container>

   <FullImage image="/assets/images/renovation.png">
    <h2 className={styles.title}>
     <span>Building Renovation</span> Services
    </h2>
    <div className={styles.text}>
     <p>
      Also at our company, we specialize in comprehensive building renovation services that breathe new life into residential and commercial
      properties.{" "}
     </p>
     <p>
      Whether you need to update an outdated structure or transform an existing space, our team is dedicated to delivering exceptional results that
      meet your needs and exceed your expectations.
     </p>
    </div>
    <CstLink href="" type="outline" title="Contact us" />
   </FullImage>
   <FullImage image="/assets/images/interior.png" reverse={true}>
    <h5 className={styles.services_title}>Our Services</h5>
    <div className={styles.boxes}>
     <div className={styles.box}>
      <img src="/assets/icons/building 1.svg" alt="Image" />
      <div className={styles.txt}>
       <h4>Residential Renovation</h4>
       <p>Enhancing the beauty and functionality of homes, from interior redesigns to structural improvements.</p>
      </div>
     </div>
     <div className={styles.box}>
      <img src="/assets/icons/renovation 1.svg" alt="Image" />
      <div className={styles.txt}>
       <h4>Residential Renovation</h4>
       <p>Enhancing the beauty and functionality of homes, from interior redesigns to structural improvements.</p>
      </div>
     </div>
     <div className={styles.box}>
      <img src="/assets/icons/historic-site 1.svg" alt="Image" />
      <div className={styles.txt}>
       <h4>Residential Renovation</h4>
       <p>Enhancing the beauty and functionality of homes, from interior redesigns to structural improvements.</p>
      </div>
     </div>
    </div>
   </FullImage>
   <FullImage image="/assets/images/whyus.png">
    <h2 className={styles.title}>
     <span>Why Choose us ?</span>
    </h2>
    <div className={styles.specifics}>
     <ol>
      <li>
       <h4>Experienced Professionals</h4>
       <p>Our team of skilled craftsmen and designers brings years of experience to every project.</p>
      </li>
      <li>
       <h4>Experienced Professionals</h4>
       <p>Our team of skilled craftsmen and designers brings years of experience to every project.</p>
      </li>
      <li>
       <h4>Experienced Professionals</h4>
       <p>Our team of skilled craftsmen and designers brings years of experience to every project.</p>
      </li>
      <li>
       <h4>Experienced Professionals</h4>
       <p>Our team of skilled craftsmen and designers brings years of experience to every project.</p>
      </li>
     </ol>
    </div>
   </FullImage>
   <Footer />
  </div>
 );
}

export default Home;
