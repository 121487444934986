import React from "react";
import styles from "./footer.module.css";
import Container from "../Container/Container";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";

export default function Footer() {
 const isMobile = useMediaQuery({ query: `(max-width: 768px)` });

  const scrollTop = ( ) => {
    window.scrollTo(0, 0);
  }

 return (
  <>
    <div className={styles.top_footer}>
      <h1>Let’s go Up, and up away with Technology!</h1>
    </div>
    <div className={styles.footer_container}>
    <Container>
      {!isMobile ? (
      <>
        <div className={styles.footer_header}>
        <img src="/assets/images/logo-footer.svg" alt="footer logo" />
        <h1>Contact us</h1>
        </div>
        <div className={styles.footer_content}>
        <div className={styles.footer_menu}>
          <div className={styles.content_menu}>
            <div className={styles.media}>
              <p>Scantech is committed to delivering exceptional products and services to meet your needs.</p>
              <div className={styles.follow}>
              <p>Follow us:</p>
              <Link to="https://www.facebook.com/scantechllc/">
                <img src="/assets/images/social-media/gg_facebook.svg" alt="social media" />
              </Link>
              <Link to="https://www.instagram.com/scantech.ks/">
                <img src="/assets/images/social-media/ri_instagram-fill.svg" alt="social media" />
              </Link>
              <Link to="">
                <img src="/assets/images/social-media/mdi_linkedin.svg" alt="social media" />
              </Link>
              <Link to="">
                <img src="/assets/images/social-media/mdi_linkedin.svg" alt="social media" />
              </Link>
              </div>
              <button onClick={scrollTop} className={styles.upbtn}>
                <img src="/assets/images/go-up.svg" alt="go-up" />
              </button>
            </div>
            <ul>
              <li><Link target="_blank" to="https://scantech-ks.com/">Home</Link></li>
              <li><Link target="_blank" to="https://scantech-ks.com/about-us">About</Link></li>
              <li><Link target="_blank" to="https://scantech-ks.com/#xray-scanners">X-Ray</Link></li>
              <li><Link target="_blank" to="">Consctruction</Link></li>
              <li><Link target="_blank" to="https://scantech-ks.com/#academy">Academy</Link></li>
              {/* <li>Blog</li> */}
            </ul>
            <ul>
              <li><Link target="_blank" to="https://scantech-ks.com/#electrity-installation">Electricity Installation</Link></li>
              <li><Link target="_blank" to="https://scantech-ks.com/#smart-tech">Smart-tech</Link></li>
              <li><Link target="_blank" to="https://scantech-ks.com/#professional-trainings">Professional Trainings</Link></li>
              <li><Link target="_blank" to="https://scantech-ks.com/contact">Contact us</Link></li>
            </ul>
          </div>
        </div>
        <hr style={{ height: "180px", margin: "60px 0" }}></hr>
        <div className={styles.footer_info}>
          <div className={styles.footer_info_content}>
          <ul>
            <li>
            Kosove <span>Scantech Group LLC</span>
            </li>
            <li>
            Office 1 - Mati 1 <span>Prishtine</span>
            </li>
            <li>
            Office 2 - Rr.Tahir Sinani<span>Lipjan</span>
            </li>
            <li><Link to="tel:+38338580000">+383 38 58 00 00</Link></li>
            <li><Link to="tel:+38348363333">+383 48 36 33 33</Link></li>
            <li><Link to="mailto:info@scantech-ks.com">info@scantech-ks.com</Link></li>
          </ul>
          <ul>
            <li>
            <span>United Kingdom</span>Scantech Agency LTD
            </li>
            <li>
            <span>Address</span> 27 Old Gloucester
            </li>
            <li><Link to="mailto:info@scantech-ks.com">info@scantech-ks.com</Link></li>
          </ul>
          </div>
        </div>
        </div>
        <hr></hr>
        <div className={styles.footer_copyright}>
        <p>© Copyright. 2024 Scantech Group LLC. All Rights Are Reserved.</p>
        <div className={styles.footer_copyright_right}>
          <p>Privacy and Policy</p>
          <p>Terms & Conditions</p>
          <p>FAQ</p>
        </div>
        </div>
      </>
      ) : (
      <>
        <div className={styles.footer_header}>
        <img src="/assets/images/logo-footer.svg" alt="footer logo" />
        </div>
        <div className={styles.footer_content}>
        <div className={styles.footer_menu}>
          <p>Scantech is committed to delivering exceptional products and services to meet your needs.</p>
          <div className={styles.content_menu}>
            <div className={styles.footer_lists}>
              <ul>
                <li><Link target="_blank" to="https://scantech-ks.com/">Home</Link></li>
                <li><Link target="_blank" to="https://scantech-ks.com/about-us">About</Link></li>
                <li><Link target="_blank" to="https://scantech-ks.com/#xray-scanners">X-Ray</Link></li>
                <li><Link target="_blank" to="">Consctruction</Link></li>
                <li><Link target="_blank" to="https://scantech-ks.com/#academy">Academy</Link></li>
                {/* <li>Blog</li> */}
              </ul>
              <ul>
                <li><Link target="_blank" to="https://scantech-ks.com/#electrity-installation">Electricity Installation</Link></li>
                <li><Link target="_blank" to="https://scantech-ks.com/#smart-tech">Smart-tech</Link></li>
                <li><Link target="_blank" to="https://scantech-ks.com/#professional-trainings">Professional Trainings</Link></li>
                <li><Link target="_blank" to="https://scantech-ks.com/contact">Contact us</Link></li>
              </ul>
            </div>
          </div>
        </div>
        <hr></hr>
        <div className={styles.footer_info}>
          <h1>Contact us</h1>
          <div className={styles.footer_info_content}>
          <ul>
            <li>
            Kosove <span>Scantech Group LLC</span>
            </li>
            <li>
            Office 1 - Mati 1 <span>Prishtine</span>
            </li>
            <li>
            Office 2 - Rr.Tahir Sinani<span>Lipjan</span>
            </li>
            <li><Link to="tel:+38338580000">+383 38 58 00 00</Link></li>
            <li><Link to="tel:+38348363333">+383 48 36 33 33</Link></li>
            <li><Link to="mailto:info@scantech-ks.com">info@scantech-ks.com</Link></li>
          </ul>
          <hr></hr>
          <ul>
            <li>
            <span>United Kingdom</span>Scantech Agency LTD
            </li>
            <li>
            <span>Address</span> 27 Old Gloucester
            </li>
            <li><Link to="mailto:info@scantech-ks.com">info@scantech-ks.com</Link></li>
          </ul>
          </div>
          <div className={styles.follow}>
          <p>Follow us:</p>
          <img src="/assets/images/social-media/gg_facebook.svg" alt="social media" />
          <img src="/assets/images/social-media/ri_instagram-fill.svg" alt="social media" />
          <img src="/assets/images/social-media/mdi_linkedin.svg" alt="social media" />
          <img src="/assets/images/social-media/mdi_linkedin.svg" alt="social media" />
          </div>
        </div>
        </div>
        <hr></hr>
        <div className={styles.footer_copyright}>
        <h4>© Copyright. 2024 Scantech Group LLC. All Rights Are Reserved.</h4>
        <div className={styles.footer_copyright_right}>
          <p>Privacy and Policy</p>
          <p>Terms & Conditions</p>
          <p>FAQ</p>
        </div>
        </div>
        <hr></hr>
        <div className={styles.go_up}>
        <button onClick={scrollTop} className={styles.upbtn}>
          <img src="/assets/images/go-up.svg" alt="go-up" />
        </button>
        </div>
      </>
      )}
    </Container>
    </div>
  </>
 );
}
