import React from "react";
import styles from "./partners.module.css";
import Container from "../Container/Container";
export default function Partners() {
 return (
  <div className={styles.partners_container}>
   <Container>
    <div className={styles.content}>
     <div className={styles.text_container}>
      <h2>OUR TRUSTED PARTNERS</h2>
      <p>
       We are proud to collaborate with leading companies, ensuring that we deliver the highest quality services backed by trusted names in the
       industry.
      </p>
     </div>
     <div className={styles.partners_icons}>
      <div>
        <img src="/assets/icons/partners/gipa.png" alt="Gipa Logo" />
      </div>
      <div>
        <img src="/assets/icons/partners/albi.png" alt="Albi Logo" />
      </div>
      <div>
        <img src="/assets/icons/partners/germia.png" alt="Germia Logo" />
      </div>
      <div>
        <img src="/assets/icons/partners/dino.png" alt="Dino Logo" />
      </div>
     </div>
    </div>
   </Container>
  </div>
 );
}
