import { Link } from "react-router-dom";
import styles from "../clickable.module.css";
import classNames from "classnames";

function CstLink({ href, type="primary", title, classes={}, target=null }) {
  return (
    <Link target={target} className={classNames({
      [styles.btn]: true, 
      [classes]: true,
      [styles.outline]: type==="outline",
      [styles.outline_white]: type==="outline-white"
    })} to={href}>{title}</Link>
  );
}

export default CstLink;
