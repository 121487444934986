import Container from "../Container/Container";
import Service from "./Service";
import styles from "./service.module.css";

function Services() {

  const services = [
    {
      icon: "/assets/icons/repairing 1.svg",
      intro: "PLUMBING",
      title: "See Our <span>Plumbing</span> Services",
      text: `
        Our plumbing services cover everything from installations to emergency repairs. 
        We handle pipe installations, leak repairs, and fixture replacements with precision. 
        Our team is equipped to tackle any drainage issue, ensuring your systems run smoothly.
        <br/> <br/>
        We also specialize in water heater installations and maintenance, 
        offering both traditional and tankless solutions to meet your needs. 
        With our 24/7 emergency plumbing services, we're always ready to assist when you need us most.
      `,
      list: {
        title: "Specific Plumbing Services:",
        items: [
          "Fixture Installations: <span>Sinks, toilets, faucets, and more.</span>",
          "Sewer Line Services: <span>Inspections, cleaning, and repairs.</span>",
          "Water Softener Systems: <span>Installation and maintenance to improve water quality.</span>"
        ]
      },
      image: "/assets/images/services/service1.png",
    },
    {
      icon: "/assets/icons/boiler 1.svg",
      intro: "HEATING",
      title: "See Our <span>Heating</span> Services",
      text: `
        Stay warm with our comprehensive heating solutions. 
        We provide expert installation and repair services for furnaces and boilers, 
        ensuring your heating systems operate efficiently. 
        <br/> <br/>
        Our heat pump services offer an energy-efficient alternative for year-round comfort. 
        Additionally, we install and maintain radiant heating systems, which provide an even, cozy warmth throughout your home.
      `,
      list: {
        title: "Specific Heating Services:",
        items: [
          "Thermostat Installation and Repair: <span>Smart thermostats and traditional models.</span>",
          "Ductwork Services: <span>Design, installation, and cleaning.</span>",
          "Hydronic Heating: <span>Energy-efficient water-based heating systems.</span>"
        ]
      },
      image: "/assets/images/services/service2.png",
    },
    {
      icon: "/assets/icons/air-conditioner 1.svg",
      intro: "AIR CONDITIONING",
      title: "See Our <span>Air Conditioning</span> Services",
      text: `
        Our air conditioning services are designed to keep your home cool and comfortable. 
        We install and repair both central and ductless AC systems, tailored to your specific requirements. 
        <br/> <br/>
        Our indoor air quality solutions include advanced filtration and purification 
        systems to keep your air clean and healthy. We also offer energy-efficient 
        upgrades that help you save on costs while reducing your carbon footprint.
      `,
      list: {
        title: "Specific Air Conditioning Services:",
        items: [
          "Seasonal Maintenance: <span>Regular check-ups to ensure optimal performance.</span>",
          "Zoning Systems: <span>Customized cooling solutions for different areas of your home.</span>",
          "Dehumidifiers: <span>Installation and maintenance for improved indoor comfort.</span>"
        ]
      },
      image: "/assets/images/services/service3.png",
    },
    {
      icon: "/assets/icons/plugin 1.svg",
      intro: "ELECTRICAL",
      title: "See Our <span>Electrical</span> Services",
      text: `
        Our electrical services ensure your home or business is safely powered. 
        We handle everything from new installations to troubleshooting and repairs. 
        Our custom lighting solutions enhance both interior and exterior spaces, adding functionality and aesthetic appeal. 
        <br/> <br/>
        Additionally, we provide panel upgrades to modernize your electrical 
        system and increase capacity, ensuring it meets current standards and demands.
      `,
      list: {
        title: "Specific Electrical Services:",
        items: [
          "Home Automation: <span>Smart home systems for enhanced control and efficiency.</span>",
          "Surge Protection: <span>Installation of whole-house surge protectors.</span>",
          "Backup Generators: <span>Ensure power continuity during outages.</span>"
        ]
      },
      image: "/assets/images/services/service4.png",
    },
  ]
 return (
  <div className={styles.services_container}>
   <Container>
    <div className={styles.content}>
     <h5 className={styles.intro}>OUR SERVICES</h5>
     <h2 className={styles.title}>
      We Provides <span>Construction Services</span>
     </h2>
     <div className={styles.services}>
      <div className={styles.service}>
       <img src="/assets/images/worker-repairing-water-heater3.png" alt="Plumbing" />
       <div className={styles.info}>
        <img src="/assets/icons/repairing 1.svg" alt="Plumbing" />
        <h4>Plumbing</h4>
       </div>
      </div>
      <div className={styles.service}>
       <img src="/assets/images/worker-repairing-water-heater4.png" alt="Heating" />
       <div className={styles.info}>
        <img src="/assets/icons/boiler 1.svg" alt="Heating" />
        <h4>Heating</h4>
       </div>
      </div>
      <div className={styles.service}>
       <img src="/assets/images/worker-repairing-water-heater1.png" alt="Air Conditioning" />
       <div className={styles.info}>
        <img src="/assets/icons/air-conditioner 1.svg" alt="Air Conditioning" />
        <h4>Air Conditioning</h4>
       </div>
      </div>
      <div className={styles.service}>
       <img src="/assets/images/worker-repairing-water-heater2.png" alt="Electrical" />
       <div className={styles.info}>
        <img src="/assets/icons/plugin 1.svg" alt="Electrical" />
        <h4>Electrical</h4>
       </div>
      </div>
     </div>
    </div>
   </Container>

    {services.map((item, key)=>(
      <Service 
        {...item}
        key={key}
        reverse={(key+1)%2==0}
      />
    ))}
  </div>
 );
}

export default Services;
