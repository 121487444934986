import Container from "../Container/Container";
import styles from "./service.module.css";
import classNames from "classnames";

function Service({ icon, intro, title, text, list, image, reverse = false }) {
 return (
  <div className={styles.service_container}>
   <Container>
    <div
     className={classNames({
      [styles.service]: true,
      [styles.reverse]: reverse,
     })}
    >
     <div className={styles.infos}>
      <div className={styles.intro}>
       <img src={icon} alt={title} />
       <h5>{intro}</h5>
      </div>
      <h3 className={styles.title} dangerouslySetInnerHTML={{__html: title}}></h3>
      <div className={styles.txt}>
       <p dangerouslySetInnerHTML={{__html: text}}></p>
      </div>

      <div className={styles.specifics}>
       <h4>{list.title}</h4>
       <ol>
        {list.items.map((item, key) => (
          <li dangerouslySetInnerHTML={{__html: item}} key={key}></li>
        ))}
       </ol>
      </div>
     </div>
     <div className={styles.image}>
      <img src={image} alt={title} />
     </div>
    </div>
   </Container>
  </div>
 );
}

export default Service;
